<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" :height="`${height}px`" :width="`${width}px`" :class="`fill-current stroke-current text-${color}`" viewBox="0 0 25 24">
    <path
      d="M9.67358 11.2266H3.29858C2.85725 11.2266 2.43399 11.0512 2.12191 10.7392C1.80984 10.4271 1.63452 10.0038 1.63452 9.5625V3.1875C1.63452 2.74616 1.80984 2.3229 2.12191 2.01083C2.43399 1.69876 2.85725 1.52344 3.29858 1.52344H9.67358C10.1149 1.52344 10.5382 1.69876 10.8503 2.01083C11.1623 2.3229 11.3376 2.74616 11.3376 3.1875V9.5625C11.3376 10.0038 11.1623 10.4271 10.8503 10.7392C10.5382 11.0512 10.1149 11.2266 9.67358 11.2266ZM20.9236 11.2266H14.5486C14.1072 11.2266 13.684 11.0512 13.3719 10.7392C13.0598 10.4271 12.8845 10.0038 12.8845 9.5625V3.1875C12.8845 2.74616 13.0598 2.3229 13.3719 2.01083C13.684 1.69876 14.1072 1.52344 14.5486 1.52344H20.9236C21.3649 1.52344 21.7882 1.69876 22.1003 2.01083C22.4123 2.3229 22.5876 2.74616 22.5876 3.1875V9.5625C22.5876 10.0038 22.4123 10.4271 22.1003 10.7392C21.7882 11.0512 21.3649 11.2266 20.9236 11.2266ZM9.67358 22.4766H3.29858C2.85725 22.4766 2.43399 22.3012 2.12191 21.9892C1.80984 21.6771 1.63452 21.2538 1.63452 20.8125V14.4375C1.63452 13.9962 1.80984 13.5729 2.12191 13.2608C2.43399 12.9488 2.85725 12.7734 3.29858 12.7734H9.67358C10.1149 12.7734 10.5382 12.9488 10.8503 13.2608C11.1623 13.5729 11.3376 13.9962 11.3376 14.4375V20.8125C11.3376 21.2538 11.1623 21.6771 10.8503 21.9892C10.5382 22.3012 10.1149 22.4766 9.67358 22.4766ZM20.9236 22.4766H14.5486C14.1072 22.4766 13.684 22.3012 13.3719 21.9892C13.0598 21.6771 12.8845 21.2538 12.8845 20.8125V14.4375C12.8845 13.9962 13.0598 13.5729 13.3719 13.2608C13.684 12.9488 14.1072 12.7734 14.5486 12.7734H20.9236C21.3649 12.7734 21.7882 12.9488 22.1003 13.2608C22.4123 13.5729 22.5876 13.9962 22.5876 14.4375V20.8125C22.5876 21.2538 22.4123 21.6771 22.1003 21.9892C21.7882 22.3012 21.3649 22.4766 20.9236 22.4766Z"
      stroke-width="0.046875"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#58595B'
    },
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
