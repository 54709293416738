<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 25 24">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4446 6H20.4446C21.5546 6 22.4446 6.89 22.4446 8V19C22.4446 20.11 21.5546 21 20.4446 21H4.44458C3.33458 21 2.44458 20.11 2.44458 19L2.45458 8C2.45458 6.89 3.33458 6 4.44458 6H8.44458V4C8.44458 2.89 9.33458 2 10.4446 2H14.4446C15.5546 2 16.4446 2.89 16.4446 4V6ZM10.4446 6H14.4446V4H10.4446V6ZM9.19458 11C8.78037 11 8.44458 11.3358 8.44458 11.75C8.44458 12.1642 8.78037 12.5 9.19458 12.5H15.6946C16.1088 12.5 16.4446 12.1642 16.4446 11.75C16.4446 11.3358 16.1088 11 15.6946 11H9.19458Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#58595B'
    },
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
