<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" :height="`${height}px`" :width="`${width}px`" :class="`fill-current stroke-current text-${color}`" viewBox="0 0 25 24">
    <path
      d="M6.0485 22.6029H4.63184C4.35591 22.6029 4.09129 22.4933 3.89619 22.2981C3.70108 22.103 3.59147 21.8384 3.59147 21.5625V15.1875C3.59147 14.9116 3.70108 14.647 3.89619 14.4519C4.09129 14.2567 4.35591 14.1471 4.63184 14.1471H6.0485C6.32442 14.1471 6.58905 14.2567 6.78415 14.4519C6.97926 14.647 7.08887 14.9116 7.08887 15.1875V21.5625C7.08887 21.8384 6.97926 22.103 6.78415 22.2981C6.58905 22.4933 6.32442 22.6029 6.0485 22.6029ZM15.9652 22.6029H14.5485C14.2726 22.6029 14.008 22.4933 13.8129 22.2981C13.6177 22.103 13.5081 21.8384 13.5081 21.5625V10.9375C13.5081 10.6616 13.6177 10.397 13.8129 10.2018C14.008 10.0067 14.2726 9.89714 14.5485 9.89714H15.9652C16.2411 9.89714 16.5057 10.0067 16.7008 10.2018C16.8959 10.397 17.0055 10.6616 17.0055 10.9375V21.5625C17.0055 21.8384 16.8959 22.103 16.7008 22.2981C16.5057 22.4933 16.2411 22.6029 15.9652 22.6029ZM20.9235 22.6029H19.5068C19.2309 22.6029 18.9663 22.4933 18.7712 22.2981C18.5761 22.103 18.4665 21.8384 18.4665 21.5625V5.97917C18.4665 5.70324 18.5761 5.43862 18.7712 5.24352C18.9663 5.04841 19.2309 4.9388 19.5068 4.9388H20.9235C21.1994 4.9388 21.464 5.04841 21.6592 5.24352C21.8543 5.43862 21.9639 5.70324 21.9639 5.97917V21.5625C21.9639 21.8384 21.8543 22.103 21.6592 22.2981C21.464 22.4933 21.1994 22.6029 20.9235 22.6029ZM11.0068 22.6029H9.59017C9.31425 22.6029 9.04963 22.4933 8.85452 22.2981C8.65941 22.103 8.5498 21.8384 8.5498 21.5625V2.4375C8.5498 2.16158 8.65941 1.89696 8.85452 1.70185C9.04963 1.50674 9.31425 1.39714 9.59017 1.39714H11.0068C11.2828 1.39714 11.5474 1.50674 11.7425 1.70185C11.9376 1.89696 12.0472 2.16158 12.0472 2.4375V21.5625C12.0472 21.8384 11.9376 22.103 11.7425 22.2981C11.5474 22.4933 11.2828 22.6029 11.0068 22.6029Z"
      stroke-width="0.0442708"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#58595B'
    },
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
