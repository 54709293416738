<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" :height="`${height}px`" :width="`${width}px`" :class="`fill-current stroke-current text-${color}`" viewBox="0 0 25 24">
    <path
      d="M16.3036 7.37305L16.3037 7.37305C16.465 7.37273 16.6247 7.40431 16.7737 7.46596C16.9228 7.5276 17.0581 7.61811 17.172 7.73226L17.1721 7.73229C17.373 7.93304 17.4981 8.19724 17.526 8.47988C17.554 8.76251 17.4831 9.0461 17.3254 9.28232C17.1677 9.51854 16.933 9.69277 16.6612 9.77534C16.3895 9.85791 16.0975 9.8437 15.835 9.73513C15.5726 9.62657 15.3559 9.43036 15.2219 9.17995C15.0879 8.92953 15.0449 8.6404 15.1001 8.36181C15.1554 8.08323 15.3055 7.83242 15.525 7.65213C15.7445 7.47183 16.0196 7.37321 16.3036 7.37305Z"
      stroke-width="0.046875"
    />
    <path
      d="M23.2689 2.34646C23.2686 2.34506 23.2686 2.34364 23.2689 2.34224C23.2292 2.16848 23.1418 2.00926 23.0165 1.88253C22.8912 1.75581 22.733 1.66663 22.5596 1.62505C21.1628 1.28427 18.9653 1.64755 16.5301 2.62255C14.0757 3.60693 11.7761 5.06005 10.2217 6.61724C9.72346 7.11281 9.26061 7.6427 8.83652 8.20302C7.7898 8.15614 6.86777 8.30521 6.09105 8.64411C3.38449 9.83521 2.61152 12.8835 2.40574 14.135C2.37596 14.3129 2.38762 14.4952 2.43981 14.6678C2.492 14.8404 2.58331 14.9986 2.70663 15.1301C2.82996 15.2616 2.98197 15.363 3.15084 15.4261C3.31972 15.4893 3.50089 15.5127 3.68027 15.4944H3.68636L6.70136 15.1654C6.70511 15.2038 6.70933 15.239 6.71261 15.2713C6.75134 15.6387 6.91518 15.9816 7.17668 16.2425L8.64808 17.7149C8.90864 17.9767 9.25149 18.1408 9.61887 18.1794L9.71965 18.1902L9.39152 21.2015V21.2075C9.37475 21.3697 9.39201 21.5336 9.44219 21.6887C9.49237 21.8439 9.57437 21.9868 9.68294 22.1085C9.79151 22.2301 9.92427 22.3277 10.0727 22.3951C10.2212 22.4625 10.3821 22.4982 10.5451 22.4999C10.6103 22.4999 10.6754 22.4946 10.7396 22.484C11.9982 22.2815 15.0451 21.5179 16.2329 18.7968C16.569 18.0252 16.7176 17.1069 16.6759 16.0621C17.2387 15.6389 17.7706 15.176 18.2673 14.6769C19.8343 13.1179 21.2921 10.8369 22.2662 8.41817C23.2361 6.01067 23.6012 3.79677 23.2689 2.34646ZM18.2504 10.544C17.8653 10.9295 17.3744 11.1922 16.8399 11.2987C16.3055 11.4052 15.7514 11.3508 15.2479 11.1424C14.7443 10.934 14.3139 10.5809 14.0111 10.1278C13.7082 9.67472 13.5466 9.14198 13.5466 8.597C13.5466 8.05202 13.7082 7.51929 14.0111 7.0662C14.3139 6.6131 14.7443 6.26001 15.2479 6.05158C15.7514 5.84316 16.3055 5.78877 16.8399 5.8953C17.3744 6.00183 17.8653 6.26449 18.2504 6.65005C18.5086 6.90433 18.7136 7.20741 18.8535 7.54166C18.9935 7.87591 19.0655 8.23465 19.0655 8.597C19.0655 8.95936 18.9935 9.3181 18.8535 9.65235C18.7136 9.9866 18.5086 10.2897 18.2504 10.544Z"
    />
    <path
      d="M8.47939 18.1143C8.298 18.0918 8.11464 18.1363 7.96376 18.2395C7.66423 18.4443 7.36329 18.6468 7.05954 18.8442C6.44501 19.2435 5.71001 18.5423 6.07517 17.9067L6.6447 16.9223C6.72612 16.8032 6.77146 16.6631 6.77528 16.5189C6.7791 16.3746 6.74123 16.2324 6.66622 16.1091C6.59121 15.9859 6.48224 15.8869 6.35238 15.824C6.22252 15.7611 6.07728 15.737 5.93407 15.7546C5.30196 15.8346 4.7144 16.1225 4.26392 16.5731C4.09236 16.7451 3.57064 17.2673 3.28986 19.2571C3.20993 19.8287 3.15955 20.404 3.13892 20.9807C3.13635 21.0808 3.15386 21.1804 3.1904 21.2736C3.22694 21.3669 3.28177 21.4518 3.35168 21.5235C3.42158 21.5952 3.50513 21.6522 3.5974 21.6911C3.68967 21.73 3.78879 21.75 3.88892 21.7499H3.90767C4.48486 21.7295 5.06061 21.6794 5.63267 21.5999C7.62345 21.3187 8.14564 20.7965 8.3172 20.6249C8.76987 20.1743 9.0571 19.5839 9.13236 18.9496C9.15646 18.7523 9.10121 18.5534 8.97877 18.3968C8.85633 18.2402 8.67671 18.1386 8.47939 18.1143Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#58595B'
    },
    width: {
      type: String,
      default: '25'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
